import React from "react"

import Layout from '../components/layout';
import Navbar from '../components/navbar'
import Footer from '../components/footer'
import FreeTrial from "../components/free-trial";
import SEO from "../components/seo";

import shiftSchedulingSrc from '../images/shift-scheduling.svg';
import filesFolderSrc from '../images/files-folder.svg';
import rotaPlannerSrc from '../images/rota-planner.png';

import LeftFeature from '../components/left-feature';
import RightFeature from '../components/right-feature';
import FeaturePararaph from '../components/feature-paragraph'

import {LaganEngineeringTestimonial} from '../components/testimonial';


const DownloadOption = ({name, link}) => {
	return (
		<div className="flex cursor-pointer">
			<span className="text-lg font-bold text-highlight-600 hover:underline"><a target="_blank" href={link} rel="noreferrer noopener">{name} </a> </span>
		</div>
	)
}


const RotaTemplates = () => (
  <Layout>
    <SEO
    	title="Free Weekly Staff Rota Templatesin Microsoft Excel, Google Sheets and PDF"
    	description="Weekly Free Downloadable and Printable Staff Rota Templates in PDF, Excel and Google Sheets."
    	 />
    <Navbar />
    <div className="bg-gray-50">
	  	<div className="relative bg-white overflow-hidden">
		  <div className="relative pt-6 pb-16 md:pb-20 lg:pb-24 xl:pb-32">
		    <div className="mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
		      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
		        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
		          <h2 className="mt-1 text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:leading-none sm:text-6xl lg:text-5xl xl:text-5xl">
		            Free Downloadable and Printable <span className="text-highlight-600">Rota Templates</span>
		          </h2>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		             Need rota templates to schedule shifts for your staff? Well TimeKeeper has you covered!
		          </p>
		          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
		            Download our free work rota templates with formulas included in Microsoft Excel, Google Sheets and PDF.
		          </p>
		        </div>
		        <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
		          <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
		            <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline">
		              <img className="w-full" src={shiftSchedulingSrc} alt="Woman planning a shift rota via Undraw" />
		            </button>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="py-8 bg-gray-50 overflow-hidden lg:py-16">
		  	<div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
			    <LeftFeature
			    	sectionTitle="PDF, Google Sheets & Excel Rota Template"
			    	title="Weekly Rota Template"
			    	body={
			    		<div>
				    		<FeaturePararaph>
					          This downloadable schedule template allows to specify shifts for employees in each day of the week. Formulas included in Microsoft Excel and Google Sheets.
					          <br />
					        </FeaturePararaph>
					        <div className="flex flex-start text-gray-500 mt-4"><span className="italic">Click template below to download.</span></div>
					        <div className="flex flex-row justify-between items-center mt-4">
					        	<DownloadOption name="Microsoft Excel" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/templates/weekly-rota-template-2.xlsx" />
					        	<DownloadOption name="Google Sheets" link="https://docs.google.com/spreadsheets/d/157XHYsuXv0eESgi8aauvJepZ1k6fh27gzRsPfaAAuKY/edit#gid=0" />
					        	<DownloadOption name="PDF" link="https://timekeeper-assets.s3.eu-west-1.amazonaws.com/templates/weekly-rota-template.pdf" />
					        </div>

					    </div>
			    	}
			    	imgSrc={filesFolderSrc}
			    	imgAlt="Weekly Staff Rota Template"
			    />

			    <RightFeature
			    	sectionTitle="Plan Your Staff Rota Online"
			    	title="Go paperless with your shift rotas!"
			    	body={
			    		<div>
					        <FeaturePararaph>
					          You might be using manual rota schedule templates because they are convenient to note employee shift information down. They aren't so convenient when it comes to making sure everyone is working their shifts, reconciling timesheets to shifts, handling breaks or just dealing with the queries 'When am I working this week'?
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	TimeKeeper is built to solve these problems and more. You can move off manual rotas to a <span className="bold-font">simple</span> online rota, time and attendance system in less than 1 hour. 1 hour away from never looking at a manual shift rota again!
					        </FeaturePararaph>
					        <FeaturePararaph>
					        	Why don't you sign up for a free 14 day trial or watch a demo below to find out more!
					        </FeaturePararaph>
					    </div>
			    	}
			    	imgSrc={rotaPlannerSrc}
			    	includeShadow={true}
			    	imgAlt="Move to Online Rota Planning Software"
			    />
			</div>
			<LaganEngineeringTestimonial />
			<FreeTrial showDemoBooking={true} part1={<span>Get rid of <span className="text-highlight-600">manual shift rotas</span> <br /> in your business today!</span>} />
		</div>
	</div>
	<Footer/>
  </Layout>
)

export default RotaTemplates;
